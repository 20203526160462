/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* html {
    min-height: calc(100% + env(safe-area-inset-top));
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    background-color: #3F51B5;
    height: 100%;
} */

body {
    overflow-y: scroll;
    /* min-height: 100%; */
}

p { 
    -webkit-text-size-adjust:100%;
    -moz-text-size-adjust:100%;
    -ms-text-size-adjust:100%; 
} 

/* @media only screen and (max-device-width: 480px) {      
    p { 
        -webkit-text-size-adjust:100%;
        -moz-text-size-adjust:100%;
        -ms-text-size-adjust:100%; 
    }               
} */
/* 
@media only screen and (min-width: 600px) {
    body {
        zoom: 0.9;
    }
} */
