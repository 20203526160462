.rendered-markdown a {
    color: rgb(63, 81, 181);
}

.rendered-markdown code {
    color: gray;
}

.rendered-markdown img {
    max-width: 100%;
}

.rendered-markdown {
    overflow-wrap: break-word;
    word-break: break-word;
    -webkit-text-size-adjust:100%;
    -moz-text-size-adjust:100%;
    -ms-text-size-adjust:100%; 
}


.rendered-markdown blockquote {
    border-left: black 2px solid;
    margin: 0;
    padding-left: 16px;
}