.task-notes-container {
    min-height: 100px;
    /* cursor: pointer; */
    /* padding: 15px; */
    /* padding-top: 15px; */
    /* margin: -15px; */
    border-radius: 5px;
    overflow-wrap: break-word;
    word-break: break-word;
}

/* .task-notes-container:hover {
    background: #efefef;
} */

/* .task-notes-container > :first-child {
    margin-top: 0px;
}

.task-notes-container > :last-child {
    margin-bottom: 0px;
} */
